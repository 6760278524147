import {useState} from 'react';
import {
  Box,
  Button,
  Collapse,
  Stack,
  Typography
} from '@mui/material';
import DialogConstruct from './DialogConstruct';
import FilterCard from './FilterCard';
import SummaryCard from './SummaryCard';
import {
  locationColor,
  operatorColor,
  productColor,
  stateColor,
  tagColor,
} from '../utils/color';
import stateDecoder from '../utils/stateDecoder';
import { PRODUCT_SUMMARIES, PRODUCTS } from "../constants/products";

const setupFilter = (array, name) =>
    !name                ? []
  : !array               ? [name]
  : array.includes(name) ? array.filter(i => i !== name)
                         : [...array, name];

const RobotSummary = ({
  filteredStates,
  operators,
  states,
  locations,
  tags,
  filterModel,
  onFilterModelChange,
  onConstruct,
}) => {
  const [open, setOpen] = useState(false);
  const onOk = (count, name) => {
    onConstruct(count, name);
    setOpen(false);
  };
  const filterHandler = (type, name) => {
    const filter = {...filterModel};
    filter[type] = setupFilter(filterModel[type], name);
    onFilterModelChange(filter);
  };
  const productItems = PRODUCTS.map((product) => ({color: productColor(product.name), name: product.name }));
  const stateItems = Object.keys(states).map(i => ({color: stateColor(i), name: stateDecoder(i)}));
  const operatorItems = operators.map(i => ({color: operatorColor(i), name: i}));
  const locationItems = locations.map(i => ({color: locationColor(i), name: i}));
  const tagItems = tags.map(i => ({color: tagColor(i), name: i}));
  const summaryCard = (states, label) =>
    <Collapse in={Object.keys(states).length > 0}>
      <SummaryCard states={states}>{label}</SummaryCard>
    </Collapse>;
  const filterCard = (items, type) =>
    <FilterCard
      items={items}
      onItemClick={name => filterHandler(type, name)}
      onResetClick={() => filterHandler(type)}
      filter={filterModel[type]}
    >
      {type}
    </FilterCard>;

  return (
    <Stack
      spacing={1}
      sx={{
        mx: 1,
        mt: 1,
        width: 300,
        maxHeight: 'calc(100vh - 80px)',
        overflow: 'scroll',
        display: 'block'
      }}
    >
      <Typography variant="h6">Summary</Typography>
      {PRODUCT_SUMMARIES.map((productSummary) => summaryCard(filteredStates[productSummary.name], productSummary.name))}
      <Typography variant="h6">Filtering</Typography>
      {filterCard(productItems, 'Product')}
      {filterCard(stateItems, 'State')}
      {filterCard(operatorItems, 'Operator')}
      {filterCard(locationItems, 'Location')}
      {filterCard(tagItems, 'Tag')}
      {
        onConstruct &&
          <Box sx={{p:2}}>
            <Button
              onClick={() => setOpen(true)}
              variant="contained"
              size="large"
            >
              Construct unit ...
            </Button>
          </Box>
      }
      <DialogConstruct
        open={open}
        onCancel={() => setOpen(false)}
        onOk={onOk}
      />
    </Stack>
  );
};

export default RobotSummary;
